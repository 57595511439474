<template>
  <div class="flex h-screen grow flex-col justify-between border-r border-gray-200 bg-white" data-cy="nav-sidebar">
    <div class="flex shrink flex-col gap-y-5 overflow-y-auto">
      <div class="flex h-16 shrink-0 items-center justify-center border-b border-gray-200">
        <NuxtLink data-cy="home-link" href="/dashboard">
          <VeezuLogo class="h-4 w-auto" />
        </NuxtLink>
      </div>

      <nav>
        <ul class="flex flex-1 flex-col gap-y-7 px-6 pb-6" role="list">
          <li v-for="section in navigationSections" :key="section.name">
            <span class="mb-2 block text-xs font-semibold text-gray-400">
              {{ section.name }}
            </span>

            <ul class="-mx-2 space-y-1" role="list">
              <li v-for="item in section.items" :key="item.name">
                <NuxtLink
                  :class="[
                    isActive(item.activeEx)
                      ? 'border-gray-200 bg-gray-50 font-medium text-gray-950 shadow-sm'
                      : 'border-transparent text-gray-700 hover:border-gray-200 hover:text-gray-950 hover:shadow-sm',
                    'group flex items-center gap-x-3 rounded-md border px-2 py-1 text-sm capitalize leading-6',
                  ]"
                  :data-cy="`nav-link-${useString().kebab(item.name)}`"
                  :to="item.href"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      isActive(item.activeEx) ? 'text-gray-950' : 'text-gray-600 group-hover:text-gray-950',
                      'size-5 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </NuxtLink>

                <div class="overflow-hidden">
                  <transition
                    enter-active-class="transition duration-100 ease-in-out"
                    enter-from-class="-translate-y-1/4 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-75 ease-in-out"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="-translate-y-1/4 opacity-0"
                  >
                    <div v-if="item.subItems && isActive(item.activeEx)" class="mx-1.5 rounded-b-md bg-gray-50">
                      <ul class="ml-3 space-y-1 border-l border-gray-200 py-2">
                        <li
                          v-for="subItem in item.subItems"
                          :key="subItem.name"
                          :class="[
                            isActive(subItem.activeEx) ? 'border-red-500' : 'border-transparent hover:border-gray-300',
                            'group -ml-px border-l-[1.5px]',
                          ]"
                        >
                          <NuxtLink
                            :class="[
                              isActive(subItem.activeEx)
                                ? 'font-medium text-gray-950'
                                : 'text-gray-700 group-hover:text-gray-950',
                              'flex items-center py-0.5 pl-3 text-xs capitalize leading-6',
                            ]"
                            :data-cy="`nav-link-${useString().kebab(subItem.name)}`"
                            :to="subItem.href"
                          >
                            <span class="truncate">
                              {{ subItem.name }}
                            </span>
                          </NuxtLink>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

    <nav class="border-t border-gray-200 px-6 py-2">
      <ul class="-mx-2 space-y-1" role="list">
        <li class="hidden lg:block">
          <button
            class="group flex w-full items-center justify-between gap-x-3 rounded-md border border-transparent px-2 py-1 text-sm leading-6 text-gray-700 hover:border-gray-200 hover:text-gray-950 hover:shadow-sm"
            data-cy="nav-link-notifications"
            type="button"
            @click="$emit('showNotifications')"
          >
            <span class="flex items-center gap-x-3 capitalize">
              <BellIcon aria-hidden="true" class="h-5 w-5 shrink-0 text-gray-600 group-hover:text-gray-950" />
              {{ $t('common.notification', 2) }}
            </span>
          </button>
        </li>

        <li>
          <NuxtLink
            :class="[
              isActive('/profile')
                ? 'border-gray-200 bg-gray-50 font-medium text-gray-950 shadow-sm'
                : 'border-transparent text-gray-700 hover:border-gray-200 hover:text-gray-950 hover:shadow-sm',
              'group flex items-center gap-x-3 rounded-md border px-2 py-1 text-sm leading-6',
            ]"
            data-cy="nav-link-profile"
            to="/profile"
          >
            <span class="flex min-w-0 items-center gap-x-3">
              <UserCircleIcon aria-hidden="true" class="h-5 w-5 shrink-0 text-gray-600 group-hover:text-gray-950" />
              <span class="sr-only">{{ $t('navigation.viewProfile') }}</span>
              <span class="truncate capitalize"> {{ user?.name || i18n.t('navigation.viewProfile') }} </span>
            </span>
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import {
  BellIcon,
  CalculatorIcon,
  CheckBadgeIcon,
  DocumentTextIcon,
  NewspaperIcon,
  RectangleGroupIcon,
  TruckIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline'

defineEmits<{
  showNotifications: []
}>()

const i18n = useI18n()

const user = useUser()

type NavigationItem = {
  name: string
  href: string
  activeEx: string
  icon: any
  subItems?: Omit<NavigationItem, 'icon' | 'subItems'>[]
}

type NavigationSection = {
  name: string
  items: NavigationItem[]
}

const navigationSections: NavigationSection[] = [
  {
    name: 'Dashboard',
    items: [
      {
        name: i18n.t('navigation.dashboard'),
        href: '/dashboard',
        activeEx: '^/dashboard',
        icon: RectangleGroupIcon,
      },
    ],
  },
  {
    name: 'Driver Partners',
    items: [
      { name: i18n.t('navigation.agreements'), href: '/agreements', activeEx: '^/agreements', icon: CheckBadgeIcon },
      {
        name: i18n.t('navigation.documents'),
        href: '/documents',
        activeEx: '^/documents',
        icon: DocumentTextIcon,
        subItems: [
          { name: i18n.t('navigation.documents'), href: '/documents', activeEx: '^/documents(?:/create|/\\d+)?$' },
          { name: i18n.t('navigation.documentTypes'), href: '/documents/types', activeEx: '^/documents/types' },
          {
            name: i18n.t('navigation.vehicleChanges'),
            href: '/documents/vehicle-changes',
            activeEx: '^/documents/vehicle-changes',
          },
        ],
      },
      {
        name: i18n.t('navigation.driverPartners'),
        href: '/driver-partners',
        activeEx: '^/driver-partners',
        icon: TruckIcon,
        subItems: [
          {
            name: i18n.t('navigation.driverPartners'),
            href: '/driver-partners',
            activeEx: '^/driver-partners(?:/create|/\\d+)?$',
          },
          {
            name: i18n.t('navigation.earnings'),
            href: '/driver-partners/earnings',
            activeEx: '^/driver-partners/earnings',
          },
          {
            name: i18n.t('navigation.payments'),
            href: '/driver-partners/payments',
            activeEx: '^/driver-partners/payments',
          },
          {
            name: i18n.t('navigation.exemptions'),
            href: '/driver-partners/exemptions',
            activeEx: '^/driver-partners/exemptions',
          },
        ],
      },
      {
        name: i18n.t('navigation.invoices'),
        href: '/invoices',
        activeEx: '^/invoices',
        icon: CalculatorIcon,
        subItems: [
          { name: i18n.t('navigation.invoices'), href: '/invoices', activeEx: '^/invoices(?:/create|/\\d+)?$' },
          {
            name: i18n.t('navigation.invoiceAdjustments'),
            href: '/invoices/adjustments',
            activeEx: '^/invoices/adjustments',
          },
          {
            name: i18n.t('navigation.billingProfiles'),
            href: '/invoices/billing-profiles',
            activeEx: '^/invoices/billing-profiles',
          },
        ],
      },
      {
        name: i18n.t('navigation.news'),
        href: '/news',
        activeEx: '^/news',
        icon: NewspaperIcon,
        subItems: [
          { name: i18n.t('navigation.newsPosts'), href: '/news', activeEx: '^/news(?:/create|/\\d+)?$' },
          { name: i18n.t('navigation.newsCategories'), href: '/news/categories', activeEx: '^/news/categories' },
        ],
      },
    ],
  },
]

function isActive(regex: string) {
  return useRoute().path.match(regex)
}
</script>
